import { put, all, call, takeLeading, select } from 'redux-saga/effects';
import types from './actionTypes';
import * as netRevenuesActions from './actions';
import rsf from '../../../helpers/firebase';
import { toDateFirebase } from '../../../helpers/sharedFunction';

const netRevenueTransformer = (netRevenue, data, countryId) => {
  // temporary fix for Spain country
  if (countryId === 'R3lvTw3q7pgfLUUIJiA3' && data.totalNetRevenue > 0) {
    data.totalNetRevenue = data.totalNetRevenue * 8.29646;
    data.netAudioVideoRevenue = data.netAudioVideoRevenue * 8.29646;
    data.netMixedRevenue = data.netMixedRevenue * 8.29646;
  }

  return {
    id: netRevenue.id,
    ...data,
    cpm:
      data.impressions > 0
        ? (data.totalNetRevenue / data.impressions) * 1000
        : 0,
    date: toDateFirebase(netRevenue, data, 'date').toDate(),
  };
};

function* fetchRevenuesSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const netRevenuesRef = rsf.firestore.createCollectionRefWithFilters(
      'dailySiteNetRevenues',
      countryId,
      startDate,
      endDate,
      filters,
      null,
      null,
      'date',
    );

    const netRevenuesSnap = yield call(
      rsf.firestore.getCollection,
      netRevenuesRef,
    );

    const netRevenues = netRevenuesSnap.docs.map((netRevenue) =>
      netRevenueTransformer(netRevenue, netRevenue.data(), countryId),
    );

    yield put(
      netRevenuesActions.fetchRevenuesSuccess(
        netRevenues,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(netRevenuesActions.fetchRevenuesFailure(error));
  }
}

function* netRevenueSaga() {
  yield all([
    takeLeading(types.FETCH_SITE_NET_REVENUES.REQUEST, fetchRevenuesSaga),
  ]);
}

export default netRevenueSaga;

import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner,
} from 'reactstrap';
import * as countriesActions from '../../store/countries/actions';
import { useDispatch } from 'react-redux';
import { Select } from '../../lib/availity/select';
import { Form, Input } from '../../lib/availity/form';
import FormErrorMessage from '../Common/FormErrorMessage';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';
import firebase from 'firebase/app';
import languageTag from '../../lib/languageTag';

const createAdvZonesFunction = firebase
  .functions()
  .httpsCallable('createAdvZones-createAdvZones');

const FormCountry = ({ loading, country, create, closeModal }) => {
  const editingCountry = country || {};
  const adsTxtRows = Array.isArray(editingCountry.adsTxtRows)
    ? editingCountry.adsTxtRows.join('\n')
    : '';

  const [active, setActive] = useState(editingCountry.active || false);

  const [advZoneGroupIdLoading, setAdvZoneGroupIdLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const adsTxtRows =
      !values.adsTxtRows || values.adsTxtRows === ''
        ? []
        : values.adsTxtRows.split('\n');

    const country = {
      ...values,
      adsTxtRows,
      active,
    };

    dispatch(
      create
        ? countriesActions.createCountry({
            ...country,
          })
        : countriesActions.updateCountry({
            id: editingCountry.id,
            ...country,
          }),
    );
    closeModal();
  };

  const createAdvZones = (values, setFieldValue) => {
    setAdvZoneGroupIdLoading(true);
    createAdvZonesFunction({ name: values.name })
      .then(({ data }) => {
        console.log(data);
        setFieldValue('adswizzZoneGroupId', data.adswizzZoneGroupId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setAdvZoneGroupIdLoading(false);
      });
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{ ...editingCountry, adsTxtRows }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" name="name" id="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="languageTag">Language</Label>
                    <Select
                      name="languageTag"
                      id="languageTag"
                      options={languageTag.map((lang) => ({
                        value: lang.tag,
                        label: lang.language,
                      }))}
                    />
                    <FormErrorMessage name="languageTag" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        name="active"
                        defaultChecked={active}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="active"
                        onClick={(e) => setActive(!active)}>
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" className="text-right">
                  {advZoneGroupIdLoading && (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                  <button
                    type="button"
                    disabled={!values.name || values.adswizzZoneGroupId}
                    className="btn btn-success waves-effect waves-light mb-2 mr-2"
                    onClick={() => createAdvZones(values, setFieldValue)}>
                    <i className="bx bx-world mr-1"></i>
                    Create zones
                  </button>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="adswizzZoneGroupId">
                      Adswizz Zone Group ID
                    </Label>
                    <Input
                      id="adswizzZoneGroupId"
                      name="adswizzZoneGroupId"
                      type="number"
                      min={0}
                    />
                    <FormErrorMessage name="adswizzZoneGroupId" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <Label htmlFor="adsTxtRows">Ads.Txt Rows</Label>
                    <Input
                      id="adsTxtRows"
                      name="adsTxtRows"
                      type="textarea"
                      style={{ height: '200px' }}
                    />
                    <FormErrorMessage name="adsTxtRows" />
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCountry;

import { createSelector } from 'reselect';
import {
  uniqueArrayByKey,
  getDates,
  camelize,
  capitalize,
  dateIsInRange,
} from '../helpers/sharedFunction';
import colors from '../components/CommonStyle/colors';
import { REVENUE_LEVEL } from '../config/revenue';
import moment from 'moment';

export const getRevenue = (level) =>
  createSelector(
    (state) => state.Revenue.revenues,
    (state) => state.SiteNetRevenue.revenues,
    (revenues, siteRevenues) => {
      if (level === REVENUE_LEVEL.AD_UNIT) return revenues;
      if (level === REVENUE_LEVEL.SITE) return siteRevenues;
      return revenues;
    },
  );

export const totalMetric = (metric, level) =>
  createSelector(getRevenue(level), (revenues) =>
    revenues.map((revenue) => revenue[metric] || 0).reduce((a, b) => a + b, 0),
  );

export const grossECPM = (adUnitType) =>
  createSelector(
    totalMetric(
      adUnitType ? `${camelize(adUnitType)}Impressions` : 'impressions',
      REVENUE_LEVEL.SITE,
    ),
    totalMetric(
      adUnitType
        ? `gross${camelize(adUnitType, false)}Revenue`
        : 'grossRevenue',
      REVENUE_LEVEL.SITE,
    ),
    (impressions, grossRevenue) =>
      impressions > 0 ? (grossRevenue / impressions) * 1000 : 0,
  );

export const netECPM = (adUnitType) =>
  createSelector(
    totalMetric(
      adUnitType ? `${camelize(adUnitType)}Impressions` : 'impressions',
      REVENUE_LEVEL.SITE,
    ),
    totalMetric(
      adUnitType
        ? `net${camelize(adUnitType, false)}Revenue`
        : 'totalNetRevenue',
      REVENUE_LEVEL.SITE,
    ),
    (impressions, totalNetRevenue) =>
      impressions > 0 ? (totalNetRevenue / impressions) * 1000 : 0,
  );

export const getProviders = createSelector(
  (state) => state.Revenue.revenues,
  (revenues) =>
    uniqueArrayByKey(revenues, 'provider').map(({ provider }) => provider),
);

export const getTypes = createSelector(
  (state) => state.Revenue.revenues,
  (revenues) => uniqueArrayByKey(revenues, 'type').map(({ type }) => type),
);

export const getRevenueSitesIds = createSelector(
  (state) => state.Revenue.revenues,
  (revenues) =>
    uniqueArrayByKey(revenues, 'siteId').map(({ siteId }) => siteId),
);

export const getRevenueByType = (type) =>
  createSelector(
    (state) => state.Revenue.revenues,
    (revenues) => revenues.filter(({ type: t }) => t === type),
  );

export const getRevenueByProvider = (provider) =>
  createSelector(
    (state) => state.Revenue.revenues,
    (revenues) => revenues.filter(({ provider: p }) => p === provider),
  );

export const drawSeriesDailyProviderGraph = (dateRange, t) =>
  createSelector(
    (state) => state.Revenue.revenues,
    getProviders,
    (revenues, providers) => {
      const { startDate, endDate } = dateRange;
      const dates = getDates(startDate.toDate(), endDate.toDate());

      let dataGraph = {
        max: -9999999999,
        min: 99999999999,
      };

      let series = providers.map((provider, index) => {
        let data = [];
        dates.forEach((day) => {
          const startDay = moment(day).utc().startOf('day').toDate();
          const endDay = moment(day).utc().endOf('day').toDate();

          const providerRevenues = revenues.filter(
            ({ provider: p }) => p === provider,
          );

          const dailyProviderRevenues = providerRevenues.filter(({ date }) =>
            dateIsInRange(new Date(date), startDay, endDay),
          );

          const grossRevenues = dailyProviderRevenues
            .map(({ grossRevenue }) => grossRevenue)
            .reduce((a, b) => a + b, 0);

          data.push([startDay.getTime(), grossRevenues]);

          dataGraph.max = Math.max(dataGraph.max, grossRevenues);
          dataGraph.min = Math.min(dataGraph.min, grossRevenues);
        });

        return {
          name: capitalize(provider),
          data,
          type: 'line',
          color: Object.values(colors)[index],
        };
      });

      return { series, ...dataGraph };
    },
  );

export const drawSeriesDailyTypeGraph = (dateRange, t) =>
  createSelector(
    (state) => state.Revenue.revenues,
    getTypes,
    (revenues, types) => {
      const { startDate, endDate } = dateRange;
      const dates = getDates(startDate.toDate(), endDate.toDate());

      let dataGraph = {
        max: -9999999999,
        min: 99999999999,
      };

      let series = types.map((type, index) => {
        let data = [];
        dates.forEach((day) => {
          const startDay = moment(day).utc().startOf('day').toDate();
          const endDay = moment(day).utc().endOf('day').toDate();

          const typeRevenues = revenues.filter(({ type: t }) => t === type);

          const dailyTypeRevenues = typeRevenues.filter(({ date }) =>
            dateIsInRange(new Date(date), startDay, endDay),
          );

          const grossRevenues = dailyTypeRevenues
            .map(({ grossRevenue }) => grossRevenue)
            .reduce((a, b) => a + b, 0);

          data.push([startDay.getTime(), grossRevenues]);

          dataGraph.max = Math.max(dataGraph.max, grossRevenues);
          dataGraph.min = Math.min(dataGraph.min, grossRevenues);
        });

        return {
          name: capitalize(type),
          data,
          type: 'line',
          color: Object.values(colors)[index],
        };
      });

      return { series, ...dataGraph };
    },
  );

export const drawSeriesSiteRevenueGraph = (dateRange, adUnitType, t) =>
  createSelector(getRevenue(REVENUE_LEVEL.SITE), (revenues) => {
    let dataGraph = {
      series: [
        {
          name: t('Impressions'),
          data: [],
          type: 'column',
          color: colors.warning,
        },
        {
          name: t('Net Revenue'),
          data: [],
          type: 'line',
          color: colors.dark,
        },
      ],
      max: [-9999999999, -9999999999],
      min: [99999999999, 99999999999],
    };

    const { startDate, endDate } = dateRange;
    if (!(moment.isMoment(startDate) && moment.isMoment(endDate)))
      return dataGraph;

    let impressionsKey = 'impressions';
    let netRevenueKey = 'totalNetRevenue';

    if (adUnitType !== 'all') {
      impressionsKey = `${camelize(adUnitType)}Impressions`;
      netRevenueKey = `net${camelize(adUnitType, false)}Revenue`;
    }

    const dates = getDates(startDate.toDate(), endDate.toDate());

    dates.forEach((day) => {
      const startDay = moment(day).utc().startOf('day').toDate();
      const endDay = moment(day).utc().endOf('day').toDate();

      const selectedRevenues = revenues.filter(({ date }) =>
        dateIsInRange(new Date(date), startDay, endDay),
      );

      const impressions = selectedRevenues
        .map((revenue) => revenue[impressionsKey] || 0)
        .reduce((a, b) => a + b, 0);

      const netRevenue = selectedRevenues
        .map((revenue) => revenue[netRevenueKey] || 0)
        .reduce((a, b) => a + b, 0);

      dataGraph.series[0].data.push([startDay.getTime(), impressions]);
      dataGraph.series[1].data.push([startDay.getTime(), netRevenue]);

      dataGraph.max[0] = Math.max(dataGraph.max[0], impressions);
      dataGraph.min[0] = Math.min(dataGraph.min[0], impressions);
      dataGraph.max[1] = Math.max(dataGraph.max[1], netRevenue);
      dataGraph.min[1] = Math.min(dataGraph.min[1], netRevenue);
    });
    return dataGraph;
  });

import '../../lib/availity/yup';
import * as yup from 'yup';

const DOMAIN_REGEXP =
  /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,})$/;
const CLASS_ID_CSS_REGEXP_LIST =
  /^(#|\.)[a-zA-Z_][a-zA-Z0-9\-_]+?(?:,(#|\.)[a-zA-Z_][a-zA-Z0-9\-_]+?)*$/g;
const CCS3_REGEXP_LIST =
  /^^[a-zA-Z\u00C0-\u017F\d#.\*>\+~=_\|\^\$:"\s\-\(\)'\[\];]*(?:,[a-zA-Z\u00C0-\u017F\d#.\*>\+~=_!\|\^\$:"\s\-\(\)'\[\];]*)*$/g;
/*
const CCS3_REGEXP_LIST =
  /^(#|\.|)?[a-zA-Z_]+[\w\d#.\*>\+~=_\|\^\$:"\s\-\(\)'\[\]]*(?:,(#|\.|)?[a-zA-Z_]+[\w\d#.\*>\+~=_\|\^\$:"\s\-\(\)'\[\]]*)*$/g;
*/
const COMMA_SEPARATED_VALUES_REGEXP_VAST_SLOTS =
  /^(?:(?:\d+)%?)(,(?:(?:\d+)%?))*(?:(?:\d+)%?)*$/;
const COMMA_SEPARATED_VALUES_REGEXP_URLS =
  /^[a-zA-Z0-9/.*\-?=_]+(?:(?:,)[a-zA-Z0-9/.*\-?=_]+)*[a-zA-Z0-9/.*\-?=_]+$/g;
const COMMA_SEPARATED_VALUES_REGEXP_VAST_URLS =
  /^[a-zA-Z0-9/.*\-_?={}&%:]+(?:(?:,)[a-zA-Z0-9/.*\-_?={}&%:]+)*[a-zA-Z0-9/.*\-_?={}&%:]+$/g;
const VAST_URL_REGEXP = /[a-zA-Z0-9/.*\-_?={}&%:]/;

const formValidation = yup.object().shape({
  name: yup.string().required(),
  logoUrl: yup.string().url(),
  setup: yup.string().required(),
  publisherId: yup.string().label('publisher').required(),
  enableTextAnalysis: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  active: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  domain: yup.string().matches(DOMAIN_REGEXP, 'invalid domain').required(),
  languageTag: yup.string(),
  synthesis: yup.string().required(),
  articleSelectors: yup
    .string()
    .label('article selectors')
    .matches(CCS3_REGEXP_LIST)
    .required(),
  excludeHTMLElements: yup
    .string()
    .label('exclude HTML elements')
    .matches(CCS3_REGEXP_LIST, {
      excludeEmptyString: true,
    }),
  articlesPublishedSince: yup
    .date()
    .max(new Date())
    .nullable()
    .transform((value, originalValue) => (Date.parse(value) ? value : null)),
  titleCleaner: yup.string(),
  titleSelector: yup.string(),
  customAudioIntroURL: yup.string().label('custom audio intro URL').url(),
  enablePremiumArticle: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  enableAudioDescription: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  publishPodcasts: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  excludeURLPatterns: yup.string().matches(COMMA_SEPARATED_VALUES_REGEXP_URLS, {
    excludeEmptyString: true,
  }),
  scrapeParams: yup.string(),
  scrapeFromAmp: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  scrapers: yup.array().of(yup.string().required()).nullable(),
  ttsVoices: yup.array().label('TTS voices').min(1).required(),
  characterLimit: yup.number().label('character limit').required().min(0),
  isTitleVoiceDifferent: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  enableQuotedText: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  prosodyRate: yup.number().required().min(50).max(200),
  synthesisCustomRules: yup.array().nullable(),
  improveHTML: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  playerTheme: yup.string().label('player theme').required(),
  enableDarkModePlayer: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  enableSemanticPlaylist: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  renderPlayerWithoutPodcast: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  renderPlaylist: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  showPodcastImage: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  forceHideStickyPlayer: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  hideStickyPlayerOnMobile: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  hideStickyPlayerOnDesktop: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  hideFixedPlayer: yup.array().nullable(),
  showFixedPlayerTooltip: yup.array().nullable(),
  heightFixedFloatingPlayer: yup.number().required(),
  advSlots: yup
    .string()
    .label('adv slots')
    .matches(COMMA_SEPARATED_VALUES_REGEXP_VAST_SLOTS, {
      excludeEmptyString: true,
    }),
  disableCookieSync: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  disableInitialAds: yup
    .boolean()
    .transform((value, originalValue) => (originalValue ? true : false)),
  initialAdProviders: yup.array().of(yup.string().required()).nullable(),
  vastUrls: yup
    .array()
    .of(yup.string().label('vast URL').required().matches(VAST_URL_REGEXP)),
  gamAdUnitId: yup.number(),
  gamAdUnitSeedingId: yup.number(),
  gamAdUnitCode: yup.string(),
  gamTruvidAdUnitId: yup.number(),
  gamTruvidAdUnitMixedId: yup.number(),
  adswizzPublisherId: yup.string(),
  adswizzZoneIds: yup.array().of(yup.string().required()).nullable(),
  tritonStationId: yup.string(),
  seedingSlots: yup.number().min(0).max(5),
  seedingVastUrl: yup
    .string()
    .label('seeding vast URL')
    .when('seedingSlots', {
      is: (value) => value > 0,
      then: yup.string().required(),
    }),
  percentageRevShareSeeding: yup
    .number()
    .label('% seeding revenue share')
    .min(0)
    .required(),
  percentageRevShareAudioVideo: yup
    .number()
    .label('% audio/video revenue share')
    .min(0)
    .required(),
  percentageRevShareMixed: yup
    .number()
    .label('% native revenue share')
    .min(0)
    .required(),
  truvidSellerId: yup.string(),
});

export default formValidation;

import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Collapse,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import * as sitesActions from '../../store/sites/actions';
import * as publishersActions from '../../store/publishers/actions';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input } from '../../lib/availity/form';
import { FieldArray } from 'formik';
import {
  SortableSelect,
  arrayMove,
  SortableMultiValue,
  SortableMultiValueLabel,
} from '../Common/SortableSelect';
import { Select } from '../../lib/availity/select';
import Date from '../../lib/availity/date';
import FormErrorMessage from '../Common/FormErrorMessage';
import TreeSelector from '../Common/TreeSelector';
import Modal from '../Common/Modal/Modal';
import ReactJson from 'react-json-view';
import FindArticleConfig from './FindArticleConfig';
import '../../lib/availity/yup';
import formValidation from './formValidation';
import { SubmitButton } from '../Common/Modal/modalActionButtons';

import { TTS_VOICES, CUSTOM_RULES } from '../../config/synthesizer';
import { SCRAPERS } from '../../config/site';
import { INITIAL_AD_PROVIDERS } from '../../config/revenue';
import { themes, fixedPlayerDevices } from '../../config/player';
import { capitalize } from '../../helpers/sharedFunction';
import languageTag from '../../lib/languageTag';
import firebase from 'firebase/app';
import moment from 'moment';

const langTags = [{ tag: '', language: 'Not specified' }, ...languageTag];

const FormSite = ({ site, loading, create, closeModal }) => {
  const editingSite = site || {};
  const [synthesisSiteRules, setSynthesisSiteRules] = useState(
    site?.synthesisSiteRules,
  );
  const [findArticleConfigModal, setArticleConfigModal] = useState(false);

  const publishers = useSelector((state) => state.Publisher.publishers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(publishersActions.syncPublishers());
  }, []);

  const handleSubmit = (values) => {
    const {
      languageTag,
      articleSelectors,
      excludeURLPatterns,
      scrapeParams,
      excludeHTMLElements,
      synthesisCustomRules,
      articlesPublishedSince,
      customAudioIntroURL,
      advSlots,
    } = values;

    const articleSelectorsArray = !articleSelectors
      ? []
      : Array.isArray(articleSelectors)
      ? articleSelectors
      : articleSelectors.split(',');

    const excludeURLPatternsArray = !excludeURLPatterns
      ? []
      : Array.isArray(excludeURLPatterns)
      ? excludeURLPatterns
      : excludeURLPatterns.split(',');

    const excludeHTMLElementsArray = !excludeHTMLElements
      ? []
      : Array.isArray(excludeHTMLElements)
      ? excludeHTMLElements
      : excludeHTMLElements.split(',');

    const advSlotsArray = !advSlots
      ? []
      : Array.isArray(advSlots)
      ? advSlots
      : advSlots.split(',');

    const site = {
      ...values,
      enableTextAnalysis: values.enableTextAnalysis || false,
      active: values.active || false,
      scrapeFromAmp: values.scrapeFromAmp || false,
      scrapers: values.scrapers || [],
      articleSelectors: articleSelectorsArray,
      excludeURLPatterns: excludeURLPatternsArray,
      excludeHTMLElements: excludeHTMLElementsArray,
      publishPodcasts: values.publishPodcasts || false,
      enablePremiumArticle: values.enablePremiumArticle || false,
      enableAudioDescription: values.enableAudioDescription || false,
      isTitleVoiceDifferent: values.isTitleVoiceDifferent || false,
      enableQuotedText: values.enableQuotedText || false,
      showPodcastImage: values.showPodcastImage || false,
      renderPlayerWithoutPodcast: values.renderPlayerWithoutPodcast || false,
      renderPlaylist: values.renderPlaylist || false,
      enableAriaMode: values.enableAriaMode || false,
      forceHideStickyPlayer: values.forceHideStickyPlayer || false,
      hideStickyPlayerOnMobile: values.hideStickyPlayerOnMobile || false,
      hideStickyPlayerOnDesktop: values.hideStickyPlayerOnDesktop || false,
      enableDarkModePlayer: values.enableDarkModePlayer || false,
      enableSemanticPlaylist: values.enableSemanticPlaylist || false,
      advSlots: advSlotsArray,
      disableCookieSync: values.disableCookieSync || false,
      disableInitialAds: values.disableInitialAds || false,
    };

    dispatch(
      create
        ? sitesActions.createSite({
            ...site,
            ...(languageTag && { languageTag }),
            ...(scrapeParams && { scrapeParams }),
            ...(synthesisCustomRules && { synthesisCustomRules }),
            ...(synthesisSiteRules && { synthesisSiteRules }),
            ...(articlesPublishedSince && { articlesPublishedSince }),
            ...(customAudioIntroURL && { customAudioIntroURL }),
          })
        : sitesActions.updateSite({
            id: editingSite.id,
            ...site,
            languageTag: languageTag || firebase.firestore.FieldValue.delete(),
            scrapeParams:
              scrapeParams || firebase.firestore.FieldValue.delete(),
            synthesisCustomRules:
              synthesisCustomRules || firebase.firestore.FieldValue.delete(),
            synthesisSiteRules:
              synthesisSiteRules || firebase.firestore.FieldValue.delete(),
            customAudioIntroURL:
              customAudioIntroURL || firebase.firestore.FieldValue.delete(),
          }),
    );
    closeModal();
  };

  const handleSynthesisSiteRules = ({ updated_src }) => {
    const check =
      updated_src.constructor === Object && Object.values(updated_src).length;
    setSynthesisSiteRules(check ? updated_src : null);
  };

  const onSortEnd = (values, key, oldIndex, newIndex) => {
    const array = values[key] || [];
    const newValue = arrayMove(array, oldIndex, newIndex);
    values[key] = newValue;
    console.log(
      'Values sorted:',
      newValue.map((i) => i.value),
    );
  };

  const handleUpdateSelectors = (selectors, values, setFieldValue) => {
    const { titleSelector, articleSelectors, excludeHTMLElements } = selectors;
    let { excludeHTMLElements: excludeElements } = values;
    setFieldValue('titleSelector', titleSelector);
    setFieldValue('articleSelectors', articleSelectors.join(','));

    if (!excludeElements) excludeElements = [];
    if (!Array.isArray(excludeElements))
      excludeElements = excludeElements.split(',');
    setFieldValue(
      'excludeHTMLElements',
      [...new Set([...excludeElements, ...excludeHTMLElements])].join(','),
    );
  };

  return (
    <Card>
      <CardBody>
        <Form
          initialValues={{
            ...editingSite,
            ttsVoices: editingSite.ttsVoices || [],
            prosodyRate: editingSite.prosodyRate || 100,
            characterLimit: editingSite.characterLimit || 0,
            heightFixedFloatingPlayer:
              editingSite.heightFixedFloatingPlayer || 100,
            percentageRevShareSeeding:
              editingSite.percentageRevShareSeeding || 50,
            percentageRevShareAudioVideo:
              editingSite.percentageRevShareAudioVideo || 50,
            percentageRevShareMixed: editingSite.percentageRevShareMixed || 70,
          }}
          initialTouched={{
            ttsVoices: true,
          }}
          validationSchema={formValidation}
          onSubmit={handleSubmit}>
          {({ isValid, values, setFieldValue }) => (
            <>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" />
                    <FormErrorMessage name="name" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="logoUrl">Logo (URL)</Label>
                    <Input id="logoUrl" name="logoUrl" />
                    <FormErrorMessage name="logoUrl" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="setup">Setup</Label>
                    <Select
                      name="setup"
                      id="setup"
                      options={[
                        { value: 'onboarding', label: 'Onboarding' },
                        { value: 'completed', label: 'Completed' },
                      ]}
                    />
                    <FormErrorMessage name="setup" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="publisherId">Publisher</Label>
                    <Select
                      name="publisherId"
                      id="publisherId"
                      options={publishers.map((publisher) => ({
                        value: publisher.id,
                        label: publisher.name,
                      }))}
                    />
                    <FormErrorMessage name="publisherId" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="enableTextAnalysis"
                        name="enableTextAnalysis"
                        checked={!!values.enableTextAnalysis}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="enableTextAnalysis">
                        Enable Text Analysis?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        name="active"
                        checked={!!values.active}
                      />
                      <label className="custom-control-label" htmlFor="active">
                        Active?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="domain">Domain</Label>
                    <Input id="domain" name="domain" />
                    <FormErrorMessage name="domain" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="languageTag">Language</Label>
                    <Select
                      name="languageTag"
                      id="languageTag"
                      options={langTags.map(({ tag, language }) => ({
                        value: tag,
                        label: language,
                      }))}
                      defaultValue={{
                        value: langTags[0].tag,
                        label: langTags[0].language,
                      }}
                    />
                    <FormErrorMessage name="languageTag" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="synthesis">Synthesis</Label>
                    <Select
                      name="synthesis"
                      id="synthesis"
                      options={[
                        { value: 'automatic', label: 'Automatic' },
                        { value: 'atFirstPlay', label: 'At First Play' },
                        { value: 'manual', label: 'Manual' },
                      ]}
                    />
                    <FormErrorMessage name="synthesis" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="publishPodcasts"
                        name="publishPodcasts"
                        checked={!!values.publishPodcasts}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="publishPodcasts">
                        Publish Podcasts Directly?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Scrape Configuration</h5>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <Label htmlFor="excludeURLPatterns">
                      Exclude URL patterns
                    </Label>
                    <Input
                      id="excludeURLPatterns"
                      name="excludeURLPatterns"
                      type="textarea"
                    />
                    <p className="font-size-12 text-muted">
                      Insert comma separated URLs with '*'
                    </p>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="scrapeParams">Scrape Parameters</Label>
                    <Input id="scrapeParams" name="scrapeParams" />
                    <p className="font-size-12 text-muted">
                      Insert GET string that will be appended to the URL
                    </p>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="scrapeFromAmp"
                        name="scrapeFromAmp"
                        checked={!!values.scrapeFromAmp}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="scrapeFromAmp">
                        Scrape From AMP?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="scrapers">Scrapers</Label>
                    <SortableSelect
                      name="scrapers"
                      id="scrapers"
                      useDragHandle
                      axis="y"
                      onSortEnd={({ oldIndex, newIndex }) =>
                        onSortEnd(values, 'scrapers', oldIndex, newIndex)
                      }
                      distance={4}
                      getHelperDimensions={({ node }) =>
                        node.getBoundingClientRect()
                      }
                      isMulti
                      options={SCRAPERS.map(({ label, value }) => ({
                        label,
                        value,
                      }))}
                      components={{
                        MultiValue: SortableMultiValue,
                        MultiValueLabel: SortableMultiValueLabel,
                      }}
                      closeMenuOnSelect={false}
                    />
                    <FormErrorMessage name="scrapers" />
                    <p className="font-size-12 text-muted">Top-down priority</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Article Configuration</h5>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light ml-4"
                    onClick={() => setArticleConfigModal(true)}>
                    <i className="fas fa-hat-wizard align-middle mr-1"></i>
                    Find Article Config
                  </button>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="articleSelectors">Article Selectors</Label>
                    <Input id="articleSelectors" name="articleSelectors" />
                    <FormErrorMessage name="articleSelectors" />
                    <p className="font-size-12 text-muted">
                      Insert comma separated values (ex. class,id,class,...)
                    </p>
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <Label htmlFor="excludeHTMLElements">
                      Exclude HTML Elements
                    </Label>
                    <Input
                      id="excludeHTMLElements"
                      name="excludeHTMLElements"
                      type="textarea"
                    />
                    <FormErrorMessage name="excludeHTMLElements" />
                    <p className="font-size-12 text-muted">
                      Insert comma separated values (ex. class,id,class,...)
                    </p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="articlesPublishedSince">
                      Articles Published Since
                    </Label>
                    <Date
                      id="articlesPublishedSince"
                      name="articlesPublishedSince"
                      format="DD/MM/YYYY"
                      max={moment()}
                    />
                    <p className="font-size-12 text-muted">
                      Manage articles published since this date
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="titleCleaner">Title Cleaner</Label>
                    <Input id="titleCleaner" name="titleCleaner" />
                    <p className="font-size-12 text-muted">
                      Remove this string from title tag
                    </p>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="titleSelector">Title Selector</Label>
                    <Input id="titleSelector" name="titleSelector" />
                    <p className="font-size-12 text-muted">
                      Use this to override scrape from title tag
                    </p>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label htmlFor="customAudioIntroURL">
                      Custom Audio Intro
                    </Label>
                    <Input
                      id="customAudioIntroURL"
                      name="customAudioIntroURL"
                      type="textarea"
                    />
                    <FormErrorMessage name="customAudioIntroURL" />
                    <p className="font-size-12 text-muted">
                      Use audio from this URL as intro (override AudioBoost
                      intro)
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="enablePremiumArticle"
                        name="enablePremiumArticle"
                        checked={!!values.enablePremiumArticle}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="enablePremiumArticle">
                        Enable Premium Article
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="enableAudioDescription"
                        name="enableAudioDescription"
                        checked={!!values.enableAudioDescription}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="enableAudioDescription">
                        Enable Audio Description
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Synthesization</h5>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="3" className="mb-2">
                  <Label htmlFor="ttsVoices">TTS Voices</Label>
                  <TreeSelector
                    tree={TTS_VOICES}
                    selected={values?.ttsVoices}
                    onCheck={(checked) => setFieldValue('ttsVoices', checked)}
                  />
                  <FormErrorMessage name="ttsVoices" />
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="prosodyRate">Prosody Rate</Label>
                    <InputGroup>
                      <Input
                        id="prosodyRate"
                        name="prosodyRate"
                        min={50}
                        max={200}
                        type="number"
                        step="1"
                        defaultValue={100}
                      />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                    <p className="font-size-12 text-muted">
                      100% = normal speed; 200% = speaking rate twice; 50% =
                      speaking rate of half
                    </p>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="characterLimit">Character Limit</Label>
                    <Input
                      id="characterLimit"
                      name="characterLimit"
                      min={0}
                      type="number"
                      step="1"
                      defaultValue={100}
                    />
                    <p className="font-size-12 text-muted">
                      Zero value = no limit
                    </p>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="isTitleVoiceDifferent"
                        name="isTitleVoiceDifferent"
                        checked={!!values.isTitleVoiceDifferent}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isTitleVoiceDifferent">
                        Title with Different Voice?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="enableQuotedText"
                        name="enableQuotedText"
                        checked={!!values.enableQuotedText}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="enableQuotedText">
                        Enable Quoted Text Detection?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <Label htmlFor="synthesisCustomRules">
                      Synthesis Custom Rules
                    </Label>
                    <Select
                      name="synthesisCustomRules"
                      id="synthesisCustomRules"
                      isMulti
                      options={Object.keys(CUSTOM_RULES).map((key) => ({
                        value: key,
                        label: key,
                      }))}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="synthesisSiteRules">
                      Synthesis Site Rules
                    </Label>
                    <ReactJson
                      name="synthesisSiteRules"
                      defaultValue="string"
                      displayObjectSize={false}
                      displayDataTypes={false}
                      enableClipboard={false}
                      onAdd={handleSynthesisSiteRules}
                      onEdit={handleSynthesisSiteRules}
                      onDelete={handleSynthesisSiteRules}
                      src={synthesisSiteRules ?? {}}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="improveHTML"
                        name="improveHTML"
                        checked={!!values.improveHTML}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="improveHTML">
                        Improve HTML with AI?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Audio Player Behavior</h5>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="playerTheme">Theme</Label>
                    <Select
                      name="playerTheme"
                      id="playerTheme"
                      options={themes}
                    />
                    <FormErrorMessage name="playerTheme" />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="enableDarkModePlayer"
                        name="enableDarkModePlayer"
                        checked={!!values.enableDarkModePlayer}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="enableDarkModePlayer">
                        Enable Dark Mode
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="enableSemanticPlaylist"
                        name="enableSemanticPlaylist"
                        checked={!!values.enableSemanticPlaylist}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="enableSemanticPlaylist">
                        Enable Semantic Playlist
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="renderPlayerWithoutPodcast"
                        name="renderPlayerWithoutPodcast"
                        checked={!!values.renderPlayerWithoutPodcast}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="renderPlayerWithoutPodcast">
                        Render Player Without Podcast?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="renderPlaylist"
                        name="renderPlaylist"
                        checked={!!values.renderPlaylist}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="renderPlaylist">
                        Render Playlist?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="showPodcastImage"
                        name="showPodcastImage"
                        checked={!!values.showPodcastImage}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="showPodcastImage">
                        Show Podcast Image?
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="enableAriaMode"
                        name="enableAriaMode"
                        checked={!!values.enableAriaMode}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="enableAriaMode">
                        Enable Accessibility Mode
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="forceHideStickyPlayer"
                        name="forceHideStickyPlayer"
                        checked={!!values.forceHideStickyPlayer}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="forceHideStickyPlayer">
                        Force Hide Sticky Player
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="hideStickyPlayerOnMobile"
                        name="hideStickyPlayerOnMobile"
                        checked={!!values.hideStickyPlayerOnMobile}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="hideStickyPlayerOnMobile">
                        Hide Sticky On Mobile
                      </label>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="hideStickyPlayerOnDesktop"
                        name="hideStickyPlayerOnDesktop"
                        checked={!!values.hideStickyPlayerOnDesktop}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="hideStickyPlayerOnDesktop">
                        Hide Sticky On Desktop
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="hideFixedPlayer">Hide Fixed Player</Label>
                    <Select
                      name="hideFixedPlayer"
                      id="hideFixedPlayer"
                      isMulti
                      options={fixedPlayerDevices}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="showFixedPlayerTooltip">
                      Show Fixed Player Tooltip
                    </Label>
                    <Select
                      name="showFixedPlayerTooltip"
                      id="showFixedPlayerTooltip"
                      isMulti
                      options={fixedPlayerDevices}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="heightFixedFloatingPlayer">
                      Height Fixed Player
                    </Label>
                    <InputGroup>
                      <Input
                        id="heightFixedFloatingPlayer"
                        name="heightFixedFloatingPlayer"
                        type="number"
                        step="1"
                      />
                      <InputGroupAddon addonType="append">px</InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Advertising</h5>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="advSlots">Adv Slots</Label>
                    <Input id="advSlots" name="advSlots" />
                    <FormErrorMessage name="advSlots" />
                    <p className="font-size-12 text-muted">
                      Insert comma separated values in seconds or percentages
                      (ex. 10,50%,70, ...)
                    </p>
                  </FormGroup>
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="disableCookieSync"
                        name="disableCookieSync"
                        checked={!!values.disableCookieSync}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="disableCookieSync">
                        Disable Cookie Sync
                      </label>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div
                      className="custom-control custom-switch mb-2"
                      dir="ltr">
                      <Input
                        type="checkbox"
                        className="custom-control-input"
                        id="disableInitialAds"
                        name="disableInitialAds"
                        checked={!!values.disableInitialAds}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="disableInitialAds">
                        Disable Initial Ads
                      </label>
                    </div>
                  </FormGroup>
                  <Collapse isOpen={!values.disableInitialAds}>
                    <FormGroup>
                      <Label htmlFor="initialAdProviders">
                        Initial Ad Providers
                      </Label>
                      <Select
                        name="initialAdProviders"
                        id="initialAdProviders"
                        isMulti
                        options={Object.values(INITIAL_AD_PROVIDERS).map(
                          (provider) => ({
                            label: capitalize(provider),
                            value: provider,
                          }),
                        )}
                      />
                    </FormGroup>
                  </Collapse>
                </Col>
                <Col md="7">
                  <FieldArray
                    name="vastUrls"
                    render={(arrayHelpers) => {
                      const vastUrls = values.vastUrls;
                      return (
                        <FormGroup>
                          <Label htmlFor="vastUrls">Vast URLs</Label>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline btn-light ml-4"
                            onClick={() => arrayHelpers.push([])}>
                            <i className="bx bx-plus font-size-11 align-middle"></i>{' '}
                            Add
                          </button>
                          {Array.isArray(vastUrls) &&
                            vastUrls.map((vastUrl, index) => (
                              <Row key={index} className="mb-2">
                                <Col md="12">
                                  <InputGroup>
                                    <Input
                                      name={`vastUrls.${index}`}
                                      type="textarea"
                                    />
                                    <InputGroupAddon addonType="append">
                                      <button
                                        className="btn btn-danger p-0"
                                        style={{ width: '31px' }}
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }>
                                        <i className="bx bx-trash d-block font-size-15" />
                                      </button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                  <FormErrorMessage
                                    name={`vastUrls.${index}`}
                                  />
                                </Col>
                              </Row>
                            ))}
                        </FormGroup>
                      );
                    }}
                  />
                  <p className="font-size-12 text-muted">
                    {`Insert URLs with macro parameters like {{url}}`}
                  </p>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="gamAdUnitId">GAM Ad Unit ID</Label>
                    <Input
                      id="gamAdUnitId"
                      name="gamAdUnitId"
                      type="number"
                      disabled={values.gamAdUnitId}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="gamAdUnitSeedingId">
                      GAM Ad Unit Seeding ID
                    </Label>
                    <Input
                      id="gamAdUnitSeedingId"
                      name="gamAdUnitSeedingId"
                      type="number"
                      disabled={values.gamAdUnitSeedingId}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="gamAdUnitCode">GAM Ad Unit Code</Label>
                    <Input
                      id="gamAdUnitCode"
                      name="gamAdUnitCode"
                      disabled={values.gamAdUnitCode}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="gamTruvidAdUnitId">
                      GAM Truvid Ad Unit ID
                    </Label>
                    <Input
                      id="gamTruvidAdUnitId"
                      name="gamTruvidAdUnitId"
                      type="number"
                      disabled={values.gamTruvidAdUnitId}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="gamTruvidAdUnitMixedId">
                      GAM Truvid Ad Unit Mixed ID
                    </Label>
                    <Input
                      id="gamTruvidAdUnitMixedId"
                      name="gamTruvidAdUnitMixedId"
                      type="number"
                      disabled={values.gamTruvidAdUnitMixedId}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="adswizzPublisherId">Adswizz Pub ID</Label>
                    <Input
                      id="adswizzPublisherId"
                      name="adswizzPublisherId"
                      disabled={values.adswizzPublisherId}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="tritonStationId">Triton Station ID</Label>
                    <Input id="tritonStationId" name="tritonStationId" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Seeding</h5>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup>
                    <Label htmlFor="seedingSlots">Seeding Slots</Label>
                    <Input
                      id="seedingSlots"
                      name="seedingSlots"
                      min={0}
                      max={3}
                      type="number"
                      step="1"
                    />
                  </FormGroup>
                </Col>
                <Col md="7">
                  <Collapse isOpen={values.seedingSlots > 0}>
                    <FormGroup>
                      <Label htmlFor="seedingVastUrl">Seeding Vast URL</Label>
                      <Input
                        id="seedingVastUrl"
                        name="seedingVastUrl"
                        type="textarea"
                      />
                      <FormErrorMessage name="seedingVastUrl" />
                    </FormGroup>
                  </Collapse>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Revenue</h5>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="percentageRevShareSeeding">
                      Seeding Revenue Share
                    </Label>
                    <InputGroup>
                      <Input
                        id="percentageRevShareSeeding"
                        name="percentageRevShareSeeding"
                        min={0}
                        type="number"
                        step="0.1"
                      />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                    <FormErrorMessage name="percentageRevShareSeeding" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="percentageRevShareAudioVideo">
                      Audio/Video Revenue Share
                    </Label>
                    <InputGroup>
                      <Input
                        id="percentageRevShareAudioVideo"
                        name="percentageRevShareAudioVideo"
                        min={0}
                        type="number"
                        step="0.1"
                      />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                    <FormErrorMessage name="percentageRevShareAudioVideo" />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="percentageRevShareMixed">
                      Native Revenue Share
                    </Label>
                    <InputGroup>
                      <Input
                        id="percentageRevShareMixed"
                        name="percentageRevShareMixed"
                        min={0}
                        type="number"
                        step="0.1"
                      />
                      <InputGroupAddon addonType="append">%</InputGroupAddon>
                    </InputGroup>
                    <FormErrorMessage name="percentageRevShareMixed" />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3 mb-2">
                <Col xs="auto" className="d-flex align-items-center">
                  <h5 className="m-0">Sellers.json Configuration</h5>
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                  <p className="font-size-12 text-muted m-0">
                    (optional if the publisher has specified a different domain
                    in the ads.txt file linked below)
                  </p>
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Label className="d-block">{`${
                    values.name || ''
                  } Ads.txt`}</Label>
                  <button
                    type="button"
                    className="btn btn-outline-dark waves-effect waves-light"
                    onClick={() =>
                      values.domain &&
                      window.open(`https://${values.domain}/ads.txt`, '_blank')
                    }>
                    <i className="bx bx-link-external align-middle mr-1"></i>
                    Open site Ads.txt
                  </button>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label htmlFor="truvidSellerId">
                      Truvid Sellers.json ID
                    </Label>
                    <InputGroup>
                      <Input id="truvidSellerId" name="truvidSellerId" />
                      <InputGroupAddon addonType="append">
                        <button
                          className="btn btn-primary p-0"
                          style={{ width: '31px' }}
                          type="button"
                          onClick={() =>
                            window.open(
                              'https://www.truvid.com/sellers.json',
                              '_blank',
                            )
                          }>
                          <i className="bx bx-link-external d-block font-size-15" />
                        </button>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormErrorMessage name="truvidSellerId" />
                    <p className="font-size-12 text-muted">
                      Enter the seller ID provided by Truvid
                    </p>
                  </FormGroup>
                </Col>
              </Row>
              <div className="modal-footer">
                <SubmitButton disabled={!isValid || loading} create={create} />
              </div>
              <Modal
                size="lg"
                title="Find Article Configuration"
                isOpen={findArticleConfigModal}
                toggle={() => setArticleConfigModal(!findArticleConfigModal)}
                scrollable={false}>
                <FindArticleConfig
                  updateSelectors={(selectors) =>
                    handleUpdateSelectors(selectors, values, setFieldValue)
                  }
                  closeModal={() => setArticleConfigModal(false)}
                />
              </Modal>
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormSite;
